/* ===================Custom Button By DuongLe====================== */
 /* customize button */
 .cus-btn {
    color: #fff;
    text-decoration: none;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 7px;
    padding-right: 7px;
    display: inline-block;
    border: none;
    transition: all 0.4s ease 0s;
  }
  .cus-btn-small {
    /* padding-top: 5px !important;
    padding-bottom: 5px !important; */
    font-size: 15px;
  }
  .cus-btn-fluid {

    align-content: center;
    text-align: center;
  }
  /*  */
  .cus-btn-green {
    color: #000;
    background: #02843d;
  }
  .cus-btn-green-600 {
    background: #43a047;
  }
  .cus-btn-green-700 {
    background: #388e3c;
  }
  .cus-btn-green-800 {
    background: #2e7d32;
  }
  .cus-btn-green-900 {
    background: #1b5e20;
  }
  /*  */
  .cus-btn-light-green {
    background: #8bc34a;
  }
  /*  */
  .cus-btn-amber {
    background: #ffc107;
  }
  /*  */
  .cus-btn-orange{
    background: #ff9800;
  }
  /*  */
  .cus-btn-red {
    background: #ff5722;
  }
   /*  */
  .cus-btn-red-400 {
    background: #ef5350;
  }
  /*  */
  .cus-btn-grey {
    background: #9e9e9e;
  }
  .cus-btn-grey-200 {
    background: #eeeeee;
  }
  .cus-btn-grey-300 {
    background: #e0e0e0;
  }
  /*  */
  .cus-btn-blue {
    background: #03a9f4;
  }
  /*  */
  .cus-btn:hover {
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    transition: all 0.4s ease 0s;
  }

/* Grow ------------------------------------------------------------------*/
.hvr-grow {
  /* display: inline-block; */
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  transform: scale(1.1);
}

/* Underline From Center */
.hvr-underline-from-center {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 0;
  background: #2098D1;
  height: 4px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-underline-from-center:hover:before,
.hvr-underline-from-center:focus:before,
.hvr-underline-from-center:active:before {
  left: 0;
  right: 0;
}

/* ---------------------------------------------------------------------- */
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 13;
		background-color: #fff;
}

/* ---------------------------------------------------------------------- */

.m-tab-wrap {
  width: max-content;
  background-color: transparent;
  /* margin-left: 20%; */
  position: relative;
  display: flex;
  /* top: -106px; */
}

input[type="radio"][name="m-tabs"] {
  position: absolute;
  z-index: -1;
}

input[type="radio"][name="m-tabs"]:checked+.m-tab-label-content .m-label {
  color: white;
}

input[type="radio"][name="m-tabs"]:checked+.m-tab-label-content .m-tab-content {
  display: block;
}

.m-label {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 56px;
  transition: color 0.2s ease;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.m-slide {
  background: #ffeb3b;
  /* width: calc(100% / 3); */
  height: 4px;
  position: absolute;
  left: 0;
  top: calc(100% - 4px);
  transition: left 0.3s ease-out;
}

.m-tab-label-content {
  width: 100%;
}

.m-tab-label-content .m-tab-content {
  position: absolute;
  top: 100px;
  left: 16px;
  line-height: 130%;
  display: none;
}

@media screen and (max-width: 800px) {
  .m-tab-wrap {
      width: 80%;
      margin-left: 10%;
      top: -106px;
  }
}
/* navigation--------------------------------------------------------- */
.m-tabs-controls {
  position: relative;
  display: flex;
  align-items: stretch;
  width: max-content;
  box-sizing: border-box;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.m-tabs-controls__link {
  cursor: pointer;
  position: relative;
  display: block;
  /* padding-left: 15px;
  padding-right: 15px;
  padding-top: 0px; */
  /* padding-bottom: 4px; */
  padding: 5px 7px;
  font-size: 20px;
  color: #4caf50;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
}
/* .m-tabs-controls__link:after {
  background: #02843D;
  border-radius: 6px;
} */
.m-tabs__sub-title {
  font-size: 15px;
  font-weight: normal;
}
.m-tabs-controls__link--active {
  background: #02843D;
  border-radius: 6px;
}
.m-tabs-controls__link--active .m-tab__title, .m-tabs-controls__link--active.fix_size_more{
  color: #000 !important;
  font-weight: bold !important;
}
/* .m-tabs-controls__link--active:after {
  background: #02843D;
  border-radius: 6px;
} */


/* Spinner Loading */

.round-loader,
.round-loader:before,
.round-loader:after {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.round-loader {
  color: green;
  font-size: 10px;
  /* margin: auto; */
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.round-loader:before,
.round-loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.round-loader:before {
  left: -1em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.round-loader:after {
  left: 1em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
/* 
  size of icon and text on each screen size
*/

@media screen and (max-width: 600px) {
  .mobile-desktop-icon {
     font-size: 18px;
  }
  .mobile-desktop-text {
    font-size: 15px;
  }
}

@media screen and (min-width: 600px) {
.mobile-desktop-icon {
  font-size: 18px;
}
.mobile-desktop-text {
 font-size: 18px;
}
}

.round-btn {
  background-color: light-grey; /* Green */
  border: none;
  color: white;
  padding: 5px 7px 5px 7px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 100%;
}

.sweet-alert.swal-cancel-red .sa-button-container button.cancel {
  background-color: #dc3545 !important;
  color: white !important;
  border-color: #dc3545 !important;
  border: 1px solid #dc3545 !important;
}
.restaurant-menu-infor .m-tabs-controls__link--active {
	border-bottom: 2px solid green !important;
	border-radius: 0px !important;
	background: none !important;
}
.restaurant-menu-infor .m-tabs-controls__link--active .m-tab__title {
	color: #000000 !important;
	font-weight: bold !important;
}
@media (min-width : 768px) {
	.restaurant-menu-infor .sticky {
    flex: 100%!important;
    top: 51px;
    right: auto;
    left: 0px!important;
    margin-left: 0 !important;
    max-width: 100%;
    width: 100%!important;
    padding: 10px!important;
		padding-left: 5%!important;
    padding-right: 5%!important;
	}
}